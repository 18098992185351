import React, { useState, useContext } from "react";
import { Button as BootstrapButton, ButtonGroup as BootstrapButtonGroup } from "reactstrap";
import {
  SubscriptionContext,
  mobileSubscriptionLookup,
  webSubscriptionLookup,
  ACTIVE_MOBILE_PRODUCTS,
  ACTIVE_PRICING_DURATIONS,
  getProductTier,
  getTierSportCount,
  getProductDuration,
} from "src/services/SubscriptionService";
import AnalyticsService from "src/services/AnalyticsService";
import GAService from "src/services/GAService";
import { DEFAULT_ONE_SPORT, DEFAULT_TWO_SPORTS, DEFAULT_THREE_SPORTS } from "src/constants";
import { DurationItem, TierItem } from "src/typings/pricing";
import { ProductOptions } from "../";
import styles from "./styles.scss";
import SportSelector from "src/components/SportSelector";
import { useFreeTrialClaimed } from "src/services/UserService";

const getProductBuyText = (subKey: string) => {
  const sportCount = getTierSportCount(getProductTier(subKey));
  const sportCountTxt = ["All Sports", "1 Sport", "2 Sports", "3 Sports"][sportCount]; // prettier-ignore

  const periodly = {
    day: "Daily",
    month: "Monthly",
    year: "Yearly",
    quarter: "Quarterly",
    week: "Weekly",
  }[getProductDuration(subKey)];

  return `${sportCountTxt} ${periodly}`;
};

const sharpIcon = require("public/icon/sharp-icon.png?sizes[]=20,sizes[]=40,sizes[]=60");
const SharpIcon = (
  <img
    src={sharpIcon.src}
    srcSet={sharpIcon.srcSet}
    alt="Sharp Tier Icon"
    className="rotoql-pricing-view__tier-sharp-icon"
  />
);

const DEFAULT_DURATION = ACTIVE_PRICING_DURATIONS[0]; // month
const DEFAULT_TIER_INDEX: number = 0;

export const TIER_INDEX_MAX_SPORTS: { [key: number]: number | null } = {
  0: 1,
  1: 2,
  2: 3,
  3: null,
};

function getDefaultSportList(tierIndex: number): string[] {
  switch (tierIndex) {
    case 0:
      return DEFAULT_ONE_SPORT;
    case 1:
      return DEFAULT_TWO_SPORTS;
    case 2:
      return DEFAULT_THREE_SPORTS;
    case 3:
      return [];
    default:
      return DEFAULT_TWO_SPORTS;
  }
}

function getTierTitle(tierIndex: number): string {
  switch (tierIndex) {
    case 0:
      return "Premium Tab";
    case 1:
      return "Pro Tab";
    case 2:
      return "VIP Tab";
    case 3:
      return "Sharp Tab";
    default:
      return "";
  }
}

const getButtonSubtext = (
  tierValue: number,
  currentTierValue: number | undefined,
  upsellText: string | null,
  cancelled: boolean
) => {
  let subtext = upsellText;
  // Check if the user is subscribed
  if (currentTierValue) {
    if (cancelled) {
      subtext = "REACTIVATE";
    }
    if (tierValue > currentTierValue) {
      subtext = "UPGRADE";
    }
    if (tierValue < currentTierValue) {
      subtext = "DOWNGRADE";
    }
    if (tierValue === currentTierValue) {
      subtext = "Current Plan";
    }
  }
  const markup = <span className="rotoql-pricing-view__purchase-button-subtext">{subtext}</span>;
  return subtext ? markup : null;
};

function TierSelector(props: { tierIndex: number; duration: DurationItem; onSelectTier(tierIndex: number): void }) {
  const { tierIndex, duration, onSelectTier } = props;
  const buttons = duration.tiers.map((tier, index) => {
    const { maxSports, price, durationDisplayAbv } = tier;
    const sportWord = maxSports === "all" || maxSports > 1 ? "Sports" : "Sport";

    return (
      <BootstrapButton
        key={index}
        active={tierIndex === index}
        onClick={() => onSelectTier(index)}
        className={`rotoql-pricing-view__sport-button-group-button ${
          tierIndex === index ? "rotoql-pricing-view__sport-button-group-button--active" : ""
        }`}
      >
        <span className="tier-sports">
          {maxSports} {sportWord}
        </span>
        <span className="tier-price">
          ${price}/{durationDisplayAbv}
        </span>
      </BootstrapButton>
    );
  });
  return <BootstrapButtonGroup className="rotoql-pricing-view__sport-button-group">{buttons}</BootstrapButtonGroup>;
}

function ProductSelector(props: {
  tierIndex: number;
  sportList: string[];
  durationKey: string;
  userSubscription?: any;
  currentTierValue?: number;
  onSelectProduct(tier: TierItem, value: number, index: number): void;
  isYearlySubscription: boolean;
}) {
  const trialClaimed = useFreeTrialClaimed();
  const ctx = useContext(SubscriptionContext);
  const { tierIndex, sportList, durationKey, isYearlySubscription } = props;
  const isDisplayAnnual = durationKey === "year";
  const maxSports = TIER_INDEX_MAX_SPORTS[tierIndex] ?? "All";
  const isSportListFull = maxSports !== "All" ? sportList.length >= maxSports : true;

  const isCurrentSubscription = (tier: TierItem) => {
    const webSub = webSubscriptionLookup[ctx.subscriptionLevel ?? ""];
    const mobileSub = mobileSubscriptionLookup[ctx.subscriptionLevel ?? ""];
    return [webSub, mobileSub].includes(tier.key);
  };

  return (
    <div className="rotoql-pricing-view__purchase-button-container">
      {ACTIVE_MOBILE_PRODUCTS[tierIndex]
        .filter((period) => {
          if (isYearlySubscription) {
            return true;
          }
          // Only return monthly if the user isn't already subscribed to yearly
          return period.durationUnit !== "year";
        })
        .map((tier: TierItem, index: number) => {
          const isSkipped = isDisplayAnnual && tier.key.includes("month");
          if (!tier.active || isSkipped) {
            return null;
          }

          const tierValue = Math.pow(10, tierIndex) + index;
          const isSharp = tier.key.includes("sharp");
          const isCurrentPlan = isCurrentSubscription(tier);
          const isCurrentPlanCancelled = isCurrentPlan && props.userSubscription.cancelAt;
          const buttonClass = "rotoql-pricing-view__purchase-button";
          const currentFlag = isCurrentPlan && !isSharp ? "--current" : "";
          const sharpFlag = isSharp ? "--sharp" : "";
          const buttonClassName = `${buttonClass}${currentFlag}${sharpFlag}`;
          const isButtonDisabled = !isSportListFull || (isCurrentPlan && !isCurrentPlanCancelled);

          const buttonSubtext = getButtonSubtext(
            tierValue,
            props.currentTierValue,
            tier.upsellText,
            isCurrentPlanCancelled
          );

          return (
            <button
              key={index}
              disabled={isButtonDisabled}
              className={buttonClassName}
              onClick={() => props.onSelectProduct(tier, tierValue, index)}
            >
              <div className="rotoql-pricing-view__purchase-button-text-container">
                {isSharp ? SharpIcon : null}
                {trialClaimed ? "Buy " : "Start Free Trial for "}
                {getProductBuyText(tier.key)}
              </div>
              {!isDisplayAnnual && buttonSubtext}
            </button>
          );
        })}
    </div>
  );
}

export interface PricingViewProps {
  style?: any;
  className?: string;
  subscriptionLevel: string | null;
  tierIndex?: number;
  sportList?: string[];
  source: string | null;
  currentTierValue?: number;
  currentTier?: TierItem;
  duration?: DurationItem;
  userSubscription?: any;
  onSelectProduct(options: ProductOptions): void;
  onGoBack(): void;
  isYearlySubscription: boolean;
}

export default function PricingView(props: PricingViewProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { isYearlySubscription, duration = DEFAULT_DURATION } = props;
  const trialClaimed = useFreeTrialClaimed();
  const [tierIndex, setTierIndex] = useState<number>(props.tierIndex ?? DEFAULT_TIER_INDEX);
  const [sportList, setSportList] = useState<string[]>(props.sportList ?? getDefaultSportList(tierIndex));
  const [tierTitle, setTierTitle] = useState<string>(getTierTitle(tierIndex));

  const selectTier = (newTierIndex: number) => {
    setTierIndex(newTierIndex);
    setTierTitle(getTierTitle(newTierIndex));
    setSportList(getDefaultSportList(newTierIndex));

    if (newTierIndex !== tierIndex) {
      AnalyticsService.track("Select Tier", {
        tier: tierTitle,
      });
    }
  };

  const selectProduct = (selectedTier: TierItem, tierValue: number, productIndex: number) => {
    const { currentTierValue, source, onSelectProduct } = props;
    const upsellTier = ACTIVE_MOBILE_PRODUCTS[tierIndex]?.[productIndex + 1] || ACTIVE_MOBILE_PRODUCTS[3][1];
    const hasAllSports = selectedTier.maxSports === "all";
    const isSportListFull = selectedTier.maxSports === "all" ? true : sportList.length >= selectedTier.maxSports;

    if (!hasAllSports && !isSportListFull) {
      return;
    }

    let newState: any = {
      sportList,
      selectedTier,
      upsellTier,
      showDowngrade: false,
      showUpgrade: false,
      showCheckout: false,
      showOfferSteps: false,
    };
    let sourceLabel = "";

    if (currentTierValue) {
      if (currentTierValue > tierValue) {
        newState.showDowngrade = true;
        sourceLabel = "Downgrade";
      }
      if (currentTierValue < tierValue) {
        newState.showUpgrade = true;
        sourceLabel = "Upgrade";
      }
    } else {
      newState.showCheckout = true;
      sourceLabel = "Checkout Modal";

      GAService.sendEvent({
        hitType: "event",
        eventCategory: "Begin Checkout",
        eventAction: window.location.href,
        eventLabel: selectedTier.key,
      });
    }
    AnalyticsService.track("Checkout Button Click", {
      screen: "PricingView",
      page: "Pricing",
      tab: selectedTier.durationTitle,
      subscriptionLevel: `${selectedTier.title} ${selectedTier.durationTitle}`,
      source: sourceLabel,
      upgradeSource: source,
    });
    onSelectProduct(newState);
  };

  // prettier-ignore
  return (
    <div
      className={`rotoql-pricing-view ${props.className}`}
      style={props.style}
    >
      <div className="rotoql-pricing-view__step-text">STEP 1</div>
      <div className="rotoql-pricing-view__step-subtext">
        How many sports do you want to access?
        {trialClaimed ? "" : <><br/>(Free for 3 days)</>}
      </div>
      <TierSelector
        tierIndex={tierIndex}
        duration={duration}
        onSelectTier={selectTier}
      />
      <div className="rotoql-pricing-view__step-text">STEP 2</div>
      <div className="rotoql-pricing-view__step-subtext">
        Select your sport(s):
        <div className="rotoql-pricing-view__change-sport-reminder">
          * You can change your sport 1x per week
        </div>
      </div>
      <SportSelector
        tier={getProductTier(ACTIVE_MOBILE_PRODUCTS[tierIndex][0].key)}
        sportList={sportList}
        setSportList={setSportList}
        className="rotoql-pricing-view__sport-selector-container"
      />
      <ProductSelector
        tierIndex={tierIndex}
        sportList={sportList}
        durationKey={duration.key}
        userSubscription={props.userSubscription}
        currentTierValue={props.currentTierValue}
        onSelectProduct={selectProduct}
        isYearlySubscription={isYearlySubscription}
      />
      <style jsx>{styles}</style>
    </div>
  );
}
